.config-component {
  height: 100%;
  transition: transform 0.5s ease-out;
    width: 420px;
    max-width: 680px;
}

.move-left {

}

.move-right {

}

.active {
  z-index: 1;
}
.inactive {
  z-index: 0;
  opacity: 0;
  display: none;
}
@keyframes MoveLeftAndFadeOut {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(-400%);
        opacity: 0;
    }

}
@keyframes MoveLeftAndFadeIn {
    0% {
        transform: translateX(200%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }

}
@keyframes MoveRightAndFadeOut {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(400%);
        opacity: 0;
    }


}
@keyframes MoveRightAndFadeIn {
    0% {
        transform: translateX(-200%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }

}
@keyframes ShakeIt {
    0% {
        transform: translateX(0);
    }
    10% {
        transform: translateX(-4px);
    }
    20% {
        transform: translateX(4px);
    }
    30% {
        transform: translateX(-4px);
    }
    40% {
        transform: translateX(4px);
    }
    50% {
        transform: translateX(-4px);
    }
    60% {
        transform: translateX(4px);
    }
    70% {
        transform: translateX(-4px);
    }
    80% {
        transform: translateX(4px);
    }
    90% {
        transform: translateX(-4px);
    }
    100% {
        transform: translateX(0);
    }

}