@import "src/App";
.save-config-content {

  justify-content: center;
  height: 64px;
}

.saved-icon {
  animation: ZoomInAndFadeIn 0.8s ease;
  font-size: 64px;
  line-height: 64px;
  color: $green;

}

@keyframes ZoomInAndFadeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}