@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

$black: #3B3B3E;
$white: #F5F5F5;
$gray: rgb(223, 218, 227);
$red: #d53e3e;
$green: #26A17B;
.theme {
  height: 100vh;
  display: flex;
  justify-content: center;

}

.theme-selector {
  display: flex;
  border-radius: 50px;
}

.theme-item {
  cursor: pointer;
  padding: 6px 10px;
  display: none;
  border-radius: 50px;
  font-size: 24px;
  color: $gray;
  transition: all 0.3s ease;
animation: ZoomIn 0.3s ease;
}
@keyframes ZoomIn {
    0% {
        transform: scale(0.75);
    }
    100% {
        transform: scale(1);
    }

}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: auto;
}

label {
  font-size: 16px;
  width: 100%;
  font-weight: 500;
  letter-spacing: 0.04em;

}

.input-group {
  display: flex;
  flex-direction: column;
  max-width: 420px;
}

.input-container {
  display: flex;
  align-items: center;
  border: 1px solid rgba(59, 59, 62, 0.4);
  border-radius: 4px;
  margin: 8px 0;
  /* background: rgba(241, 240, 240, 0.4);*/
  background: rgb(233, 229, 237);
}

.disabled {
  background: none;
  border: 1px solid $white;
  color: inherit;
}

.disabled .input-icon {
  border-right: 1px solid $white;
}

.disabled i::before {
  border-radius: 50%;
  border: 1px solid $white;
  background-color: $white;
}

.disabled .input-field {
  color: $white;
}

.input-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 8px;
  border-right: 1px solid rgba(59, 59, 62, 0.4);
  color: initial;
  font-size: 24px;
}

.input-icon span {
  font-size: 24px;
  color: $black;
  padding-bottom: 0.1em;
}

.input-field {
  flex: 1;
  padding-left: 8px;
  border: none;
  letter-spacing: 0.04em;
  font-size: 20px;
  outline: none;
  background: transparent;
}

textarea {
  width: 100%;
  border: none;
  outline: none;
  height: 64px;
  background: transparent;
  font-size: 16px;
  letter-spacing: 0.04em;
  padding: 8px;
  font-family: unset;
}

.the-count {
  display: flex;
  align-self: end;
  color: initial;
  float: right;
  width: 0;
  font-weight: 400 !important;
  font-size: 0.875rem;
  position: relative;
  top: 84px;
  right: 44px;
}

input {
  color: $black;
}

input::placeholder {
  color: inherit;
  opacity: 0.5;
}

.input-footer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 4px 0;
}

.input-error {
  color: #d53e3e;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px dashed rgba(223, 218, 227, 0.5);
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  margin: 8px 0;
}

.profile-picture-preview {
  border-radius: 50%;

  max-width: 120px;
  align-self: center;
  margin-top: 12px;
}

.uploader-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 0 8px 8px;
  fill: $gray;
}

.config-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.config-title small {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;

}

.config-title small i {

}

.config-title svg {
  width: 20px;
  height: 20px;
  margin-top: 4px;
}

.config-title path {
  stroke: $white;
}

.delimiter {
  border-top: 1px dashed $black;
  align-self: center;
  margin: 4px 0;
  height: 1px;
  background: none;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 17px 75px;
  height: 48px;
  gap: 10px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: inherit;
  cursor: pointer;
  width: 100%;
  transition: width 0.3s ease-in-out;
}

.btn .btn-primary {
}

.btn .btn-secondary {
}

.btn-transparent {
  background: transparent;
  color: $red;
  width: 50%;
  align-self: center;
  height: 40px;
  border: 1px solid $red;

}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 420px;
  gap: 8px;
  transition: all 0.3s ease-in-out;
  align-self: center;

}
.animated-left{
  animation: MoveLeftAndFadeIn 0.5s ease-in-out;
}

/*input {
  background: rgba(233, 230, 230, 0.4);
  border: 1px solid rgba(59, 59, 62, 0.4);
  border-radius: 4px;
  font-size: 16px;
  padding: 8px 0;
  width: 100%;
  text-anchor: start;
  color: inherit;
  letter-spacing: 0.04em;
  font-family: inherit;
}*/


.container {
  height: 100%;
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: column;


}

.frame {
  height: 100%;
  width: 855px;
  /*display: flex;
  flex-direction: column;*/
  display: grid;
  align-items: start;
  align-self: center;
  grid-template-columns: 1fr;
  grid-template-rows: 64px 1fr;


  /*justify-content: center;*/
  gap: 48px;
  box-shadow: 0px 0px 2px 1px rgba(59, 59, 62, 0.2);
  padding-top: 64px;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background: #3B3B3E;
    width: 8px;
    border-radius: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray;
    border-radius: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    cursor: pointer;
  }

  &::-webkit-scrollbar-track-piece {
    cursor: pointer;
  }
}

.frame-header {
  /* background-image: url('./assets/svg/logo.svg');*/
  /*  position: absolute;
    top: 4%;
    display: flex;
    width: 100%;
    max-height: 61px;*/
  align-self: start;
  justify-self: center;

}

.logo {
  /* min-height: 61px;
   margin-left: 40%;*/
  margin-left: 54px;
}

h1 {
  margin-top: unset;
}

h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin: 20px 0;

}

.frame-content {
  height: auto;
  border-radius: 12px;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px 80px;
  align-self: center;
}

.frame-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  /* gap: 8px;
   margin-bottom: 32px;
 font-size: 28px;*/

}

.move-left {
  animation: MoveLeftAndFadeOut 1s ease-out;

}

#wallets-input {
  font-size: 16px;
}

#wallet-icon {
  font-size: 20px;
}

#what-is-it {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.avatar-large {
  width: 128px;
  height: 128px;
  border-radius: 50%;

}

p {
  margin: 8px 0;
}
.loader{
/*color: $green;*/
}

/*@keyframes MoveLeftAndFadeOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}*/

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}


