@import "src/App";

.word-animation {
align-items: center;
}

.welcome-to {
    animation: fade-in-out 4s ease-in-out forwards;
}
.welcome-logo{
    animation: fade-in-move-top 4s ease-in-out forwards;
}
.welcome-lets-go{
    animation: fade-in-out 4s ease-in-out forwards;
}

@keyframes fade-in-out {
     0% {
        opacity: 0;
    }
    36% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-in-move-top {
    0% {
        opacity: 0;
    }
    36% {
        opacity: 1;
    }
    80% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 1;
        transform: translateY(-551px);
    }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}