.tooltip {
  position: relative;
  display: flex;
cursor: pointer;
  &-text {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    width: 200px;
   /* white-space: nowrap;*/
    padding: 12px;
    border-radius: 5px;
    font-size: 12px;
    background-color: #333;
    color: #fff;

    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #333 transparent;
    }
  }

  &.tooltip-top {
    .tooltip-text {
      top: -750%;
      left: 50%;
      transform: translateX(-50%);
      &::after {
        top: 100%;
        left: 50%;
        margin-left: -5px;
        transform: rotate(180deg);
      }
    }
  }

  &.tooltip-right {
    .tooltip-text {
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
    }
  }

  &.tooltip-bottom {
    .tooltip-text {
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.tooltip-left {
    .tooltip-text {
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
    }
  }

  &:hover .tooltip-text {
    visibility: visible;
  }
}
