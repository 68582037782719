@import "src/App";
.loader-large {
  display: inline-block;
  width: 48px;
  height: 48px;
  position: relative;
  border: 4px dashed $gray;
  border-radius: 50%;
  animation: loader 1.6s infinite ease-in-out;

  &::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-color: $gray;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    animation: loader-inner 1.6s infinite ease-in-out;
  }
}
.loader-small {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  border: 2px dashed $gray;
  border-radius: 50%;
  animation: loader 1.6s infinite ease-in-out;

  &::after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background-color: $gray;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    animation: loader-inner 1.6s infinite ease-in-out;
  }
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}