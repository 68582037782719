@import "src/App";

.payment-pay-to {

  display: grid !important;
  /* grid-template-columns: repeat(3, 1fr);*/
  grid-template-columns: 128px 2fr 1fr;
  grid-template-rows: 1fr;
  gap: 28px; /* optional, adds spacing between grid items */
  margin: 24px 0;

}

.username-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.username {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.about {
  align-self: start;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.payment-socials {
  justify-self: end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .payment-socials-item {
    margin: 0 4px;
    cursor: pointer;
    font-size: 24px;

    a {
      color: inherit;
      font-size: 16px;
    }
  }
}

.payment-line {
  display: flex;
  gap: 24px;

  margin: 20px 0;
  justify-content: space-between;
}

.coin-selector {
  background: rgba(69, 23, 21, 0.04);
  border-radius: 4px;
  padding: 0 36px;
  text-transform: uppercase;
  display: flex;
  gap: 8px;
  align-items: center;
  height: 60px;
  width: max-content;

  .coin-selector-icon {
    width: 32px;
    height: 32px;
  }

  label {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: normal;
    color: inherit;
  }
;

  svg {
    width: 32px;
    height: 32px;
  }
}

.locked-input {
  background: rgba(233, 230, 230, 0.4);
  border: 1px solid rgba(59, 59, 62, 0.2);
  border-radius: 4px;
  padding: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  cursor: default;
  width: max-content;
  color: inherit;
  display: flex;
  align-items: center;
}