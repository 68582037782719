
.confirm-configuration{
width: 680px;
    display: flex;
    flex-direction: column;


    .confirm-configuration__title{
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    .confirm-configuration__subtitle{
        font-size: 1.2rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }
    .confirm-configuration__button{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}