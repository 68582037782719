@import "src/App";
.dark {
  background: linear-gradient(107.56deg, #001810 0%, #0A0016 42.92%, #120000 90%);
  color: $white;
  background-size: 200% 200%;
  animation: gradient 16s ease infinite;

  transition: left 1s ease-in;
}
.dark .logo path {
  fill: $white;
}

.dark .logo line {
  stroke: $white;
}
.dark .frame {
  background: rgba(73, 73, 73, 0.5);
  box-shadow: 0px 0px 2px 1px rgba(205, 205, 217, 0.53);

}
.dark .frame-content{
  /*
  background: rgba(73, 73, 73, 0.5);
  */
  /*
  box-shadow: 0px 0px 2px 1px rgba(205, 205, 217, 0.53);
  */


}
.dark .delimiter{
  border-top: 1px dashed $white;
}
.dark .btn-primary{
  background: $white;
  color: $black;
}

.dark .btn-secondary{
  background: none;
  border: 1px solid $gray;
  color: $white;
}

.dark .disabled {
  background: none;
  border: 1px solid $black;
  color : inherit;
}
.dark .disabled .input-icon {
  border-right: 1px solid $black;
}
.dark .coin-selector{
    background: rgba(73, 73, 73, 0.4);

}
.dark .locked-input{
    background: rgba(78, 73, 81, 0.4);
    border: 1px solid rgba(105, 105, 105, 0.5);
}
.dark .theme-item {

  &.active {
    display: block;
    color: $gray;
    background: rgba(223, 218, 227, 0.4);
    transition: all 0.3s ease;

    &:hover {
      color: #ffd714;
    }
  }
}