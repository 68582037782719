@import "src/App";

$config-bg-color: #f5f5f5;
$input-bg-color: #fff;
$input-height: 50px;
$input-padding: 10px;
$input-border-color: #ddd;
$input-border-radius: 5px;

.socials-config {

}

.socials-container {
  display: grid !important;
  grid-template-columns: repeat(3, 2fr);
  margin: 8px 0;
  gap: 16px;

}

.social-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*  background: rgba(73, 73, 73, 0.5);*/
  /*
    margin-bottom: 8px;*/
  cursor: pointer;
  /*  border: 1px solid $gray;*/
  border-radius: 4px;
  padding: 16px 0;

  &:hover {
    background: rgba(73, 73, 73, 0.5);
    box-shadow: 0px 0px 2px 1px rgba(205, 205, 217, 0.53);

    label {

      font-weight: 600 !important;
    }

    i {

    }
  }

  .tile-icon {

    font-size: 24px;


  }

  .tile-icon i::before {

/*    padding: 12px;*/
  }

  .tile-text {
    cursor: pointer;
    margin-top: 4px;

    label {

      cursor: pointer;
      font-weight: 400;
    }

  }

}

.social-input-remove {
  font-size: 20px;
  color: $red;
  cursor: pointer;
  margin-right: 8px;
}
.social-input{
  animation: MoveLeftAndFadeIn 0.5s ease;
}

