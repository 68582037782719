@import "src/App";

.light {
  background: linear-gradient(107.56deg, #E9FAF4 0%, #F1E8FC 42.92%, #FFF5F5 90%);
  color: $black;
  background-size: 200% 200%;
  animation: gradient 16s ease infinite;
  transition: background 1s ease-out;
}

.light .frame {
  background: rgba(245, 245, 245, 0.32);
}

.light .frame-content {
  /* background: rgba(255, 255, 255, 0.5);*/

  /*  box-shadow: 0px 0px 2px 1px rgba(59, 59, 62, 0.2);*/

}

.light .delimiter {
  border-top: 1px dashed $black;
}

.light .btn-primary {
  background: $black;
  color: $white
}

.light .btn-secondary {
  background: $gray;
  color: $black;
}
.light .custom-file-upload {
  border: 1px dashed rgba(59, 59, 62, 0.5);}
.light .uploader-icon {
  fill: $black;
}
.light .config-title path {
  stroke: $black;
}

.light .disabled {
  background: none;
  border: 1px solid $gray;
  color : inherit;
}
.light .disabled .input-icon {
  border-right: 1px solid $gray;
}
.light .theme-item {

  &.active {
    background: rgba(59, 59, 63, 0.1);
    display: block;
    color: rgba(59, 59, 63);

    transition: all 0.3s ease;

    &:hover {
      color: #ee6b00;

    }
  }

}
.light .loader-large {
  border: 4px dashed rgba(59, 59, 63, 0.64);
  &::after {
    background-color: rgba(59, 59, 62, 0.8);
  }
}