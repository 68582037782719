@import "src/App";
.info-box{
  background: rgba(225, 220, 219, 0.2);
  border-radius: 4px;
  padding: 12px 16px;
  color: #c9c9c9;
  margin: 20px 0;
  h2{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;

    margin: unset;
  }
  p{
    margin: 12px 0 0 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

  }
  .light &{
    background: rgba(225, 220, 219, 0.5);
    color: #959496;
  }
}